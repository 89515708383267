import { AuthorContainer, AuthorTop } from "./style";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const divideIntoWords = (text) => {
  return text.split(" y ").map((author, index) => (
    <p key={index}>
      {author.split(" ").map((data, dataIndex) => (
        <span key={`${index}-${dataIndex}`}>{data} </span>
      ))}
    </p>
  ));
};

const insertBr = (text, pos) => {
  let subStr = '<br />'
  let arr = text.trim().split(' ')
  arr.splice(pos, 0, subStr)
  return arr.join(' ');
}

const splitName = (text) => {
  let splitArray = text.split(" ");
  let arrayCount = splitArray.length;
  return `${arrayCount == 2 ? insertBr(text, 1) : insertBr(text, 2)}`
};

export const Authors = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  
  // si no tengo props, busco contexto
  if(Object.keys(props).length === 0 && !!formatContent) {
    
    props = { 
        ...props,        
        listAuthors: formatContent?.storyContainer?.data?.authors ?? [],
        authorPlace: formatContent?.storyContainer?.data?.authorPlace ?? "",
        addPathAuthor: formatContent?.storyContainer?.data?.addPathAuthor ?? false,
    };
  }  

  const { listAuthors = [], authorPlace = "", addPathAuthor = false } = props;

  const hasSingleAuthor = listAuthors.length === 1;
  
  return (
    <AuthorTop className={`list-authors ${listAuthors.length > 0 ? "has-border-top" : ""} speakable-author`}>
      {listAuthors.length > 0 ? (
        listAuthors.map((author, index) => {
        
          const hasImage = Boolean(author.imageUrl);
          const shouldShowImage = listAuthors.length === 1 && hasImage;
          const authorInfoClass = divideIntoWords(author.name).length === 1 ? "single-author" : "multiple-authors";
          const linkAuth0r = listAuthors.length === 1 ? splitName(author.name) 
                              : index + 1 === listAuthors.length ? " y " +author.name
                              : index === listAuthors.length -2 ? author.name 
                              : author.name+", "
           
          let imageUrlAuthor = "";                              

          if (shouldShowImage) {

          const imageUrl = author.imageUrl ;                    
        
          imageUrlAuthor = imageUrl.includes("images.clarin.com") ? imageUrl.replace("http://images.clarin.com", process.env.NEXT_PUBLIC_IMAGE_DOMAIN) : process.env.NEXT_PUBLIC_IMAGE_DOMAIN + author.imageUrl;
          }
          
          return (
            <AuthorContainer className={`bottom-story`} key={`author${index}`} hasImage={shouldShowImage}>
              {shouldShowImage && (
                <picture>
                  <img
                    width={shouldShowImage ? 60 : 0}
                    height={shouldShowImage ? 60 : 0}
                    src={ imageUrlAuthor }
                    alt={author.name}
                  />
                </picture>
              )}
              {author.slug ? (
                <a href={`/autor/${ (!!addPathAuthor) ? addPathAuthor : ''}${author.slug}.html`} className={shouldShowImage ? 'with-image' : 'without-image'}>
                  <div className={`author-info ${authorInfoClass} ${hasSingleAuthor ? 'flex-column' : ''}`}>
                    <div suppressHydrationWarning
                      dangerouslySetInnerHTML={{
                        __html: linkAuth0r,
                      }}
                      />
                      {hasSingleAuthor && <span className="author-place">{authorPlace}</span>}

                  </div>
                </a>
              ) : (
                <span className="redaccion">Redacción Clarín</span>
              )}
            </AuthorContainer>
          );
        })
      ) : (
        <span>Redacción Clarín</span>
      )}
    </AuthorTop>
  );
};

import { AuthorOpinionContainer } from "./style";
import SocialNetworking from "@/shared/SocialNetworking";
import ShareFooter from "../../../../../Shared/Footer/components/Share";
import SocialNetworkingAuthor from "@/shared/SocialNetworkingAuthor";

export const AuthorsSpecial = ({ listAuthors, title, url, volanta }) => {
  return listAuthors?.length > 0 ? (
    listAuthors.map((author, index) => {
      return (
        <AuthorOpinionContainer key={`author${index}`}>
          <picture>
            {author.imageUrl && (
              <img
                width="100"
                height="100"
                src={`https://www.clarin.com/img${author.imageUrl}`}
                alt={author.name}
              />
            )}
          </picture>
          <div className="authorData">
            {author.slug && (
              <a className="author" href={`/autor/${author.slug}.html`}>{author.name}</a>
            )}
            {volanta && <span>{volanta}</span>}
            <SocialNetworkingAuthor
              mail={author.email}
              twitter={author.twitter}
              facebook={author.facebook}
              instagram={author.instagram}
              iconColor={"#000"}
            />
          </div>
        </AuthorOpinionContainer>
      );
    })
  ) : (
    <></>
  );
};

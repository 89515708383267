import React from "react";
import { TagsContainer, Tags } from "./style";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

export const Tag = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  
  // si no tengo props, busco contexto
  if(Object.keys(props).length === 0 && !!formatContent) {
    
    props = { 
        ...props,        
        tags: formatContent?.storyContainer?.data?.tags ?? []
    };
  }  

  const { tags = [] } = props;

  const getTagURL = (tag) => {
    const baseHref = tag.subject === "recipes" ? "/temas/recetas" : "/tema";
    const url = `${baseHref}/${tag.slug}.html`;
    const eventValue = tag.name;
    return { url, eventValue };
  };

  return (
    <TagsContainer>
      <hr/>
      <span>Tags relacionados</span>
      <Tags>
        {tags.map((tag, index) => {
          const { url, eventValue } = getTagURL(tag);
          return (
            <li className="tag" key={`tag${index}`}>
              <a href={url} onClick={() => handleClick("ui_interaction", "notas", eventValue)}>
                {tag.name}
              </a>
            </li>
          );
        })}
      </Tags>
    </TagsContainer>
  );
};

import Separator from "@/shared/Separator";
import NewsletterContainer from "./style";

export const NewsletterRelated = () => {
  return (
    <NewsletterContainer className="Newsletter trinity-skip-it">
      <div className="separator-title">
        <Separator sectionClass={"sepFullAdaptive"} tag="p" title={"Newsletter Clarín"} />
      </div>
      <div className="inner-card-m">
        <div className="related-content">
          <div className="text">
            <p>{"Recibí en tu email todas las noticias, coberturas, historias y análisis de la mano de nuestros periodistas especializados"}</p>
            <a className="button-link" href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/newsletters`}>QUIERO RECIBIRLO</a>
          </div>
          <img src={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/clarin-newsletter.png`} alt="Newsletter Clarin" loading="lazy" width="110" height="110"/>
        </div>
      </div>
    </NewsletterContainer>
  );
};

import { LinkAbsolute } from "@/shared/LinkAbsolute";
import { FeaturedNews, FeaturedNewsContainer } from "./style";
import { useGetRecommenderRaul } from '../../hooks/useGetRecommenderRaul';
import Separator from "@/shared/Separator";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

const Raul = ({ contentId = '', tags, title = 'Noticias destacadas' }) => {

  const { values, isLoading, z_control } = useGetRecommenderRaul(contentId, tags);
  const [countComments, setCountComments] = useState(Array(6).fill(0));
  const router = useRouter();

  // const getDataComments = async (idNews, index) => {
  //   const response = await fetch(`/api/viafoura/comments/${idNews}`);
  //   const { data } = await response.json();
  //   setCountComments(state => {
  //     const newArray = [...state];
  //     newArray[index] = data?.total_visible_content ?? 0;
  //     return newArray
  //   });
  // };

  // useEffect(() => {
  //   values.forEach((element, index) => {
  //     getDataComments(element.content_id, index);
  //   });
  // }, [values])
  return (
    <>
      {
        (isLoading == true)
          ? null
          : <FeaturedNews>
            <FeaturedNewsContainer>
            <hr/>
            <h2 className="title">{title}</h2> 
              <ul>
                {values.map((value, index) => {
                  const title = (value.title) ? value.title : '';
                  const subtitle = (value.subtitle) ? value.subtitle : '';
                  return (
                    <li style={{ position: "relative" }} key={`RecommenderRaul-${index}`}>
                      <LinkAbsolute 
                        href={value.url} 
                        data-box={index+1}
                        data-nota={contentId}
                        data-control={z_control}
                        data-content={value.content_id}
                        onClick={() => clickContent({contentType: value.title, contentList: "te puede interesar"},value.url,router.asPath)}
                      />
                      <picture>
                        {(value.foto && value.foto.length)
                          ? <img width="100" height="100" src={value.foto} alt={subtitle} title={title} loading="lazy" />
                          : <img width="100" height="100" src="https://www.clarin.com/frontcdn/pages/2022/08/01/lazy_square.png" alt={subtitle} title={title} loading="lazy" />
                        }
                      </picture>
                      <p>{title}</p>
                      {/* <div className="comments"><span>{countComments[index]} comentarios</span></div>  */}
                    </li>
                  );
                })}
              </ul>
            </FeaturedNewsContainer>
          </FeaturedNews>
      }
    </>
  )
};

export default Raul;

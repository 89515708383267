import styled from "styled-components";
import {breakpoints, Font, Color} from "@/shared/Constants";
export const PowerBeansBox= styled.div`
  margin-bottom:25px;
  position:relative;
  /* min-height:96px; */
  .speaker{
    display:none;//BORRAR DESPUES DE CAMBIO DE POWERBEANS
    //display: flex; //descomentar luego de cambio de powerbeans y sacar el display:none
    ${Font.clarinVarSub2Bold};
    width: max-content;
    font-size:14px;
    padding: 5px 11px;
    position: absolute;
    z-index: 6;
    background: ${Color.greyf1f1f1};
    right: 10px;
    top: 12px;
    align-items: center;
    border-radius: 10px;
    span{
      margin-right: 3px;
    }
    > div{
      width: 30px;
      padding-right: 30px;
    }
    svg{
      width: 26px;
      height: 19px;
    }
   
    &:before{
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-right: 9px solid #d1d1d15e;
      border-bottom: 6px solid transparent;
      left: -9px;
      top: 12px;
    }
    ${breakpoints.phone} {
      font-size: 13px;
      top: 10px;
      right: 5px;
      span{display:none;}
    }
  }
`
import { getCookieInRender, getPathWithRouter, parseData, parseKeyword } from "helpers";
import Script from 'next/script';
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const DfpNews = (props) => {

    const dataContext = useContext(TemplateContext);
    const { content: formatContent, ...rest } = dataContext || {};
    // si no tengo props, busco contexto
    if(Object.keys(props).length === 0 && !!formatContent) {
        props = { 
            ...props,
            isPreview: rest?.isPreview ?? false,
            section: formatContent?.sectionNameAds,
            adsPath: formatContent?.storyContainer?.data?.adsPath ? formatContent.storyContainer.data.adsPath : "seccion/nota",
            tags: formatContent?.tags,
            url: formatContent?.url,
            featuredContent: formatContent?.featuredContent ?? false,
            id: formatContent?._id
        };
    } 

    const {
        isPreview = false,
        adsPath = 'seccion/nota',
        section,
        tags = "",
        url = "",
        featuredContent = false,
        id
    } = props;

    if (isPreview) {
        return <></>;
    }
    const arrayTags = tags ? parseData(tags, "name", true) : [''];
    const urlKeywords = url ? parseKeyword(url) : [''];
    
    let sectionNorm = section.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");

    let adsPathMod = section != "arq" ? "clarin/"+adsPath : adsPath;
    return (
        <Script
            id="dfp-news"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                const isApp = ${getPathWithRouter("app") || getCookieInRender("app")};
                let adskv = "";
                
                const ppid = (id = '') => {
                    if(id.includes('|')){
                        id = 'UID-' + id.replace('|','');
                    }
                    //apple tiene '.'
                    id = id.replaceAll('.','-');
                    return id;        
                }

                let dataUser = "";
                if (window.localStorage.getItem("wads_userProfile")) {
                    dataUser = window.localStorage.getItem("wads_userProfile");
                    dataUser = JSON.parse(dataUser);
                    dataUser = dataUser.value != undefined || dataUser.value != null  ? dataUser.value : dataUser;
                }
                adskv ={
                    adsPath : ['${adsPathMod}'],
                    section: '${sectionNorm}' != undefined ? ['${sectionNorm}'] : [''],
                    kw_referer: [''+document.referrer+''],
                    kw_pw: [''+WeizenBockCompatible.getSubscriptionStatus(dataUser)+''],
                    kw_time: [''+Date.now()+''],
                    kw_choque: [''+WeizenBockCompatible.getPaywallShock()+''],
                    kw_width: [''+window.screen.availWidth+''],
                    kw_height: [''+window.screen.availHeight+''],
                    kw_keywords: ${urlKeywords ? urlKeywords : ['']},
                    kw_tags: ${arrayTags},
                    kw_login_edad: dataUser != '' ? [''+WeizenBockCompatible.getAge(dataUser?.fechaNacimiento)+''] : [],
                    kw_login_sexo: dataUser != '' ? [''+dataUser?.genero+''] : [],
                    appnativa: isApp == true ? ['1'] : ['0'],
                    kw_contenido_relevante: ${featuredContent === true ? "['true']" : "['false']"},
                    kw_article_id: ['${id}']
                };
                console.log("adskv", adskv);
                

                /* CONFIGURACION ADS*/
                var flotante2full = false;
                var flotante3full = false;                                                                                                                   
                var flotante4full = false;
                var interstitialSlot;
                window.googletag = window.googletag || {cmd: []};
                googletag.cmd.push(function() {
                    var pageConfig = {
                        allowOverlayExpansion: true,
                        allowPushExpansion: true,
                        sandbox: true
                    };
                    googletag.pubads().addEventListener('slotRenderEnded', function(event) {
                        var slot = event.slot;
                        console.groupCollapsed('Slot', slot.getSlotElementId(), 'finished rendering.');
                        console.log('Advertiser ID:', event.advertiserId);
                        console.log('Campaign ID: ', event.campaignId);
                        console.log('Creative ID: ', event.creativeId);
                        console.log('Is empty?:', event.isEmpty);
                        console.log('Line Item ID:', event.lineItemId);
                        console.log('Size:', event.size);
                        console.log(
                        'Source Agnostic Creative ID:', event.sourceAgnosticCreativeId);
                        console.log(
                        'Source Agnostic Line Item ID:', event.sourceAgnosticLineItemId);
                        console.groupEnd();
                    
                        if (slot === targetSlot2) {
                            console.log('Is empty?:', event.isEmpty);
                            flotante2full = !event.isEmpty;
                        }
                        if (flotante2full) {
                            document.getElementById('b-main-footer-cerrar').setAttribute("style", "display: flex;");
                        } 
                        if (slot === targetSlot3) {
                            console.log('Is empty?:', event.isEmpty);
                            flotante3full = !event.isEmpty;
                        }

                    });

                    googletag.pubads().setSafeFrameConfig(pageConfig);

                    interstitialSlot = googletag.defineOutOfPageSlot('/242767168/${adsPathMod}/flotante7', googletag.enums.OutOfPageFormat.INTERSTITIAL);
            
                    if (interstitialSlot) {
                        interstitialSlot.addService(googletag.pubads());
                        console.log('Interstitial is loading...');
                        googletag.pubads().addEventListener('slotOnload', function(event) {
                            if (interstitialSlot === event.slot) {
                                console.log('Interstitial is loaded.');
                            }
                        });
                    }

                    var targetSlot1 = googletag.defineOutOfPageSlot('/242767168/${adsPathMod}/flotante1', 'div-gpt-ad-flotante1').addService(googletag.pubads());

                    var mapping = googletag.sizeMapping()
                    .addSize([1280, 0], [[950, 90], [970, 90], [900, 50], [728, 90]])
                    .addSize([0, 0], [320, 50])
                    .build();
                    var targetSlot2 = googletag.defineSlot('/242767168/${adsPathMod}/flotante2', [[728, 90], [970, 90], [900, 50], [320, 50], [950, 90]], 'div-gpt-ad-flotante2')
                    .addService(googletag.pubads())
                    .defineSizeMapping(mapping);

                    var mapping = googletag.sizeMapping()
                    .addSize([0, 0], [1,1])
                    .build();
                    var targetSlot3 = googletag.defineSlot('/242767168/${adsPathMod}/flotante3', [1,1], 'div-gpt-ad-flotante3')
                    .addService(googletag.pubads())
                    .defineSizeMapping(mapping);

                    var mapping = googletag.sizeMapping()
                    .addSize([1024, 0], [])
                    .addSize([0, 0], [320, 50])
                    .build();
                    var targetSlot4 = googletag.defineSlot('/242767168/${adsPathMod}/flotante4', [[320, 50]], 'div-gpt-ad-flotante4')
                    .addService(googletag.pubads())
                    .defineSizeMapping(mapping);

                    window.targetSlot5 = googletag.defineOutOfPageSlot('/242767168/${adsPathMod}/flotante5', 'div-gpt-ad-6905052-1').addService(googletag.pubads());
                    var mapping = googletag.sizeMapping()
                    .addSize([1366, 0], [50,200])
                    .build();
                    var targetSlot6 = googletag.defineSlot('/242767168/${adsPathMod}/flotante5', [[50,200]], 'div-gpt-ad-6905052-2')
                    .addService(googletag.pubads())
                    .defineSizeMapping(mapping);

                    var targetSlot7 = googletag.defineSlot('/242767168/${adsPathMod}/flotante6', [[1,1]], 'div-gpt-ad-flotante6').addService(googletag.pubads());
                    
                    var mapping = googletag.sizeMapping()
                    .addSize([975, 0], [[728, 90], [950, 200], [950, 90], [970, 200], [970, 100], [950, 100], [1920, 200]])
                    .addSize([0, 0], [[320, 50], [320, 90], [320, 100]])
                    .build();
                    var targetSlot8 = googletag.defineSlot('/242767168/${adsPathMod}/horizontal1', [[320, 50], [320, 90], [320, 100], [728, 90], [950, 200], [950, 90], [970, 200], [950, 100], [970, 100], [1920, 200]], 'div-gpt-ad-horizontal1')
                    .addService(googletag.pubads())
                    .defineSizeMapping(mapping);

                    var mapping = googletag.sizeMapping()
                    .addSize([1024, 0], [[300, 250], [300, 600]])
                    .addSize([0, 0], [[300, 250], [300, 600], "fluid"])
                    .build();
                    var targetSlot9 = googletag.defineSlot('/242767168/${adsPathMod}/caja0', [[300, 250], [300, 600], "fluid"], 'div-gpt-ad-caja0')
                    .addService(googletag.pubads())
                    .defineSizeMapping(mapping);

                    
                    googletag.pubads()
                    .setTargeting('section', [adskv?.section])
                    .setTargeting('kw_referer', [adskv?.kw_referer])
                    .setTargeting('kw_pw', adskv?.kw_pw)
                    .setTargeting('kw_time', adskv?.kw_time)
                    .setTargeting('kw_choque', [adskv?.kw_choque])
                    .setTargeting('kw_width', adskv?.kw_width)
                    .setTargeting('kw_height', adskv?.kw_height)
                    .setTargeting('kw_keywords', [adskv?.kw_keywords])
                    .setTargeting('kw_tags', [adskv?.kw_tags])
                    .setTargeting('kw_login_edad', adskv?.kw_login_edad)
                    .setTargeting('kw_login_sexo', [adskv?.kw_login_sexo])
                    .setTargeting('kw_contenido_relevante', [adskv?.kw_contenido_relevante])
                    .setTargeting('kw_article_id', [adskv?.kw_article_id]);
                    
                    if(dataUser !== "" && typeof dataUser.id !== 'undefined'){
                        googletag.pubads().setPublisherProvidedId(ppid(dataUser.id));
                    } 
                    googletag.pubads().disableInitialLoad();                   
                    googletag.pubads().enableSingleRequest();
                    googletag.pubads().collapseEmptyDivs();
                    googletag.enableServices();

                    googletag.pubads().refresh([
                        interstitialSlot,
                        targetSlot1,
                        targetSlot2,
                        targetSlot3,
                        targetSlot4,
                        //targetSlot5,
                        targetSlot6,
                        targetSlot7,
                        targetSlot8,
                        targetSlot9,
                        // targetSlot10,
                        // targetSlot11,
                        // targetSlot12,
                        // targetSlot13,
                        // targetInread,
                        // targetInread3,
                        //targetSlot20
                        ]);
                });
            `,
            }}
        />

    );

};
export default DfpNews;

import Raul from "./components/Raul";
import Taboola from "./components/Taboola";
// import { useGetLocation } from '@/hooks/index';

const Recommender = ({ contentId = '', tags = [] }) => {

    // const { location } = useGetLocation();

    return (
        <>
            <Raul contentId={contentId} tags={tags} />
            {/* {
                (location.name == 'AR')
                    ? <Raul contentId={contentId} tags={tags} />
                    : <Taboola />
            } */}
        </>
    );
}

export { Recommender };
import dynamic from "next/dynamic"; 
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const Versions = {
    live_blog: dynamic(() => import('./Liveblog')),
    mega: dynamic(() => import('./Mega')),
    nota_libre: dynamic(() => import('./NotaLibre')),
    normal: dynamic(() => import('./Normal')),
    small: dynamic(() => import('./Small')),
    special: dynamic(() => import('./Normal')),
    opinion: dynamic(() => import('./Opinion')),
}
export const StoryContainer = () => {    
    const dataContext = useContext(TemplateContext);
    const { storyContainer } = dataContext?.content || {};
    const TargetComponent = Versions[storyContainer?.type];
    return <TargetComponent /> 
};

 
import ContentIcon from "../ContentIcon";
import { ShareAuthor } from "./style";

const SocialNetworkingAuthor = ({twitter, instagram, facebook, mail, iconColor = "#555"}) => {
  return (
    <ShareAuthor>
      {twitter && (
        <li className="socialLink">
          <a
            className="twitter"
            href={`https://www.twitter.com/${
              twitter?.trim().charAt(0) == "@"
                ? twitter.trim().replace("@", "")
                : twitter?.trim()
            }`}
            target="_blank"
          >
            <ContentIcon fill={iconColor} nameIcon="twitter" />
          </a>
        </li>
      )}
      {instagram && (
        <li className="socialLink">
          <a
            className="twitter"
            href={`https://www.instagram.com/${
              instagram?.trim().charAt(0) == "@"
                ? instagram.trim().replace("@", "")
                : instagram?.trim()
            }`}
            target="_blank"
          >
            <ContentIcon fill={iconColor} nameIcon="instagram" />
          </a>
        </li>
      )}
      {facebook && (
        <li className="socialLink">
          <a
            className="twitter"
            href={`https://www.facebook.com/${facebook?.trim()}`}
            target="_blank"
          >
            <ContentIcon fill={iconColor} nameIcon="facebook" />
          </a>
        </li>
      )}
      {mail && (
        <li className="socialLink">
          <a href={`mailto:${mail}`} >
            <ContentIcon fill={iconColor} nameIcon="mail2" />
          </a>
        </li>
      )}
    </ShareAuthor>
  );
};

export default SocialNetworkingAuthor;

import { Fragment } from "react";
import Head from "next/head";

const LinkPreconnect = () => {
  const links = [
    "https://securepubads.g.doubleclick.net",
    "https://www.googletagmanager.com",
    "https://www.googletagservices.com",
    "pagead2.googlesyndication.com",
    "vd.trinitymedia.ai"
  ];
  return (
    <Head>
      {links &&
        links.map((url, index) => (
          <Fragment key={`${index}Links`}>
            <link rel="preconnect" href={url} />
            <link rel="dns-prefetch" href={url} />
          </Fragment>
        ))}
    </Head>
  );
};

export default LinkPreconnect;

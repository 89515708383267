import { useEffect, useRef } from "react";
import { options } from "./config";

const JWPlayer = ({
  id,
  statePlayerHover = "",
  autostart = true,
  controls = true,
  mute = true,
  repeat = false,
  entityId = "",
  playerScript,
  thumbnail = "",
  title = "",
  type,
  setDuration,
  handleEventComplete = () => {},
  configType = 'default'
}) => {
  const nameCustomEvent = `jwPlayerScriptLoaded-${id}`;
  const videoRef = useRef();
  const playerInstance = useRef(null);
  const idDivPlayer = type + entityId;
  const comsCore = "https://census-web.scorecardresearch.com/plugins/streaming-jwplayer/v2/current.js";
  const publisherIdComscore = "6906409";
  // Función para instalar el script de JWPlayer
  const installPlayerScript = () => {
    const jwPlayerScriptId = `jwPlayerLibrary-${id}`; // Usamos `id` para el identificador
    if (!document.getElementById(jwPlayerScriptId)) {
      const jwPlayerScript = document.createElement("script");
      jwPlayerScript.id = jwPlayerScriptId;
      jwPlayerScript.src = process.env.NEXT_PUBLIC_JWPLAYER_ID;
      jwPlayerScript.async = true;
      jwPlayerScript.onload = () => {
        document.dispatchEvent(new CustomEvent(nameCustomEvent));
        initialize();
      };
      document.body.appendChild(jwPlayerScript);
    }

    installComsCoreScript();
  };

  // Función para instalar el script de Comscore
  const installComsCoreScript = () => {
    if (!document.getElementById("comsCoreScript")) {
      const comesCore = document.createElement("script");
      comesCore.id = "comsCoreScript";
      comesCore.src = comsCore;
      comesCore.async = true;
      document.body.appendChild(comesCore);
    }
  };

  let config;
  // Verificar si playerScript es un string no vacío y parece un JSON válido antes de parsear
  if (
    playerScript &&
    typeof playerScript === "string" &&
    playerScript.trim().startsWith("{") &&
    playerScript.trim().endsWith("}")
  ) {
    try {
      config = JSON.parse(playerScript);
    } catch (error) {
      console.error("Error al parsear playerScript:", error);
      config = options?.[configType] ?? {};
    }
  } else {
    config = options?.[configType] ?? {};
  }
  
  // Función para inicializar el reproductor
  const initialize = () => {
    document.removeEventListener(nameCustomEvent, initialize);
    if (!window.jwplayer) {
      console.error('JWPlayer no está cargado correctamente.');
      return;
    }
    const player = window.jwplayer(videoRef.current);
    playerInstance.current = player;
    const playerOpts = {
      controls: controls,
      image: thumbnail,
      playlist: `https://cdn.jwplayer.com/v2/media/${entityId}`,
      mute: mute,
      repeat: repeat,
      autoPause: {
        viewability: true,
        pauseAds: true
      },
      ...config
    };
    if (videoRef.current) {
      player.setup(playerOpts);
      player.on('ready', function() {
        if(setDuration) { setDuration(this.getDuration()) };
        const labelVideo = JSON.parse(window.jwplayer(idDivPlayer).getPlaylistItem().comscore);
        
        ns_.ComscoreJWPlayerPlugin(player, {
          publisherId: publisherIdComscore,
          labelmapping: `c3="${labelVideo.c3}", c4="*null", c6="*null", ns_st_st="${labelVideo.c3}", ns_st_pu="Clarin Digital", ns_st_pr="${labelVideo.ns_st_pr}", ns_st_ep="${labelVideo.ns_st_ep}", ns_st_sn="*null", ns_st_en="*null", ns_st_ge="News", ns_st_ia="0", ns_st_ce="0", ns_st_ddt="*null", ns_st_tdt="*null"`
        });
      });
      playbackEvents();
    }
  };

  // Función para manejar eventos de reproducción
  const playbackEvents = () => {
    window.jwplayer(videoRef.current).on("play", function () {
      sendData("resume");
    });
    window.jwplayer(videoRef.current).on("pause", function () {
      sendData("pause");
    });
    window.jwplayer(videoRef.current).on("complete", function () {
      sendData("complete");
      handleEventComplete();
    });
    window.jwplayer(videoRef.current).on("firstFrame", function () {
      sendData("start");      
    });
    window.jwplayer(videoRef.current).on("adStarted", function () {
      sendData("adStarted");
    });
    window.jwplayer(videoRef.current).on("adPlay", function () {
      sendData("adPlay");
    });
    window.jwplayer(videoRef.current).on("adPause", function () {
      sendData("adPause");
    });
    window.jwplayer(videoRef.current).on("adComplete", function () {
      sendData("adComplete");
    });
  };

  // Función para enviar datos de eventos
  const sendData = (event) => {
    if (
      event === "start" ||
      event === "start2" ||
      event === "adstart" ||
      event === "adplay"
    ) {
      CLATimer.pause();
    } else if (
      event === "pause" ||
      event === "complete" ||
      event === "adpause" ||
      event === "adcomplete"
    ) {
      CLATimer.resume();
    }

    const extraParams = {
      event: "trackEvent",
      eventCategory: "Video",
      eventAction: event,
      eventLabel: `${entityId} - ${title}`,
      eventValue: window.jwplayer(videoRef.current).getDuration(),
      nonInteraction: false,
    };
    if (typeof sendGAPageview === "function") {
      sendGAPageview(extraParams);
    }
  };

  useEffect(() => {
    const existingLibraryScript = document.getElementById(`jwPlayerLibrary-${id}`);
    if (!existingLibraryScript) {
      installPlayerScript();      
    } else if (window?.jwplayer) {
      initialize();
    } else {
      document.addEventListener(nameCustomEvent, initialize, { once: true });
    }    

    return () => {
      if (playerInstance.current && typeof playerInstance.current.remove === "function") {
        playerInstance.current.remove();
      } else if (window.jwplayer && typeof window.jwplayer(videoRef.current)?.remove === "function") {
        window.jwplayer(videoRef.current).remove();
      }
      playerInstance.current = null;
    };
  }, []);

  useEffect(() => {
    if (playerInstance.current) {
      if (statePlayerHover == "play") {
        playerInstance.current.play();
      } else if (statePlayerHover == "pause") {
        playerInstance.current.pause();
      }
    }
  }, [statePlayerHover]);

  return <div id={idDivPlayer} ref={videoRef}></div>;
};

export default JWPlayer;

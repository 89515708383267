import { getSocialNetworking, showSharePopUp } from "helpers";
import ContentIcon from "@/shared/ContentIcon";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import { Social } from "./style";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const SocialNetworking = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  // si no tengo props, busco contexto
  if(!Object.keys(props).includes('socialNetworkingSelect') && !!formatContent) {
      props = { 
          ...props,
          socialNetworkingSelect: formatContent?.storyContainer?.data?.socialNetworkingSelect,
          title: formatContent?.storyContainer?.data?.title,
          url: formatContent?.storyContainer?.data?.url,
          countComments: formatContent?.storyContainer?.data?.countComments,
          commentsStatus: formatContent?.storyContainer?.data?.commentsStatus,
      };
  } 

  const {
    fill = "black",
    typeGA4 = "notas",
    socialNetworkingSelect = [],
    title = "",
    url = "",  
    countComments = "",
    commentsStatus = true,
  } = props;

  const filterSocialNetworkin = getSocialNetworking(socialNetworkingSelect);
  const countCommentsParseString = countComments.toString();

  return (
    <Social className="SocialMedia">
      <ul className="socialContainer">
        {filterSocialNetworkin.map((social, index) =>
          url ? (
            <li key={`socialNetworking${index}`}>
              <button
                aria-label={social.name}
                data-red={social.name}
                onClick={() => {
                  showSharePopUp(social, url, title);
                  handleClick("ui_interaction", typeGA4, social.name);
                }}
              >
                <ContentIcon social nameIcon={social.name} fill={fill} />
              </button>
            </li>
          ) : (
            <li key={`socialNetworking${index}`}>
              <a
                target="_blank"
                aria-label={social.name}
                href={social.url}
                onClick={() =>
                  handleClick("ui_interaction", typeGA4, social.name)
                }
              >
                <ContentIcon nameIcon={social.name} fill={fill}  width="21.5px" height="17.5px"/>
              </a>
            </li>
          )
          )}
      </ul>
        { commentsStatus && countCommentsParseString  && <ul><li className="comments-number"><img src="/img/comments.svg" alt="comentarios" width="160" height="160" loading="lazy" /><a href="#activateComments" className="number">{countCommentsParseString}</a></li></ul>}
    </Social>
  );
};
export default SocialNetworking;

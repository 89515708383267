import ContentImage from "@/shared/ContentImage";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import { getDataImageFromRelated, getImageByClippings } from "helpers";
import Separator from "@/shared/Separator";
import { Fragment, useEffect, useState } from "react";
import { FeaturedNews, FeaturedNewsContainer } from "../../components/Recommender/components/Raul/style";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import Banner from "@/widgets/Banner/Templates/Banner";
import { BannerSmallContent } from "@/shared/ContentBanner";
import { useRouter } from "next/router";

export const DiscoverNews = (props) => {
  const router = useRouter();
  const {
    title,
    innerWidth,
    isPreview = false,
    idBucket = "discover-lx",
    limit = 6,
    content = [],
    tag="h2",
    suscribersOnly = false,
    idNew = undefined,
  } = props;

  const banners = [];
  const [news, setNews] = useState(content);
  const [countComments, setCountComments] = useState(Array(6).fill(0));

  const getContentDiscover = async (idBucket, suscribersOnly = false) => {
    try {
      const endpoint = (suscribersOnly === false)
        ? `/api/contentsBucket/${idBucket}`
        : `/api/contentsSuscribersOnly`;
      const response = await fetch(endpoint);
      let { data } = await response.json();
      if (suscribersOnly && idNew) {
        data = data.filter((item) => item._id !== idNew);
      }
      setNews(data);
    } catch (error) {
      console.error("Error al obtener el contenido de DiscoverNews");
    }
  };
  const DynamicTag =  tag  
  
  useEffect(() => {
    getContentDiscover(idBucket, suscribersOnly);
  }, []);

  return news?.length > 0 ? (
    <FeaturedNews>
      <FeaturedNewsContainer>
        <hr />
        <DynamicTag className="title">{title}</DynamicTag>
          <ul>
            {news?.slice(0, limit).map((element, index) => {
              /* cuando se le cambie la url const value = element.value */
              const value = element?.value ?? element;
              const dataImageRelated = getDataImageFromRelated(value.related);
              return (
                <Fragment key={`discoverNews${index}`}>
                  <li
                    style={{ position: "relative" }}
                    key={`discoverNews${index}`}
                  >
                    <LinkAbsolute
                      href={value.url}
                      aria-label={value.title}
                      onClick={() =>
                        clickContent(
                          {
                            contentType: value.title,
                            contentList: "noticias destacadas",
                          },
                          value.url,
                          router.asPath
                        )
                      }
                    />
                    <ContentImage
                      data={getImageByClippings(
                        {
                          desktop: ["16_9_medianas"],
                          mobile: ["16_9_medianas"],
                        },
                        dataImageRelated.clippings
                      )}
                      // alt={dataImageRelated.epigraphe}
                      alt={value.title}
                    />
                    <p>{value.title}</p>
                    {/* <div className="comments"><span>{countComments[index]} comentarios</span></div> */}
                  </li>
                  {innerWidth <= 1023 && banners.length > 0 && (
                    <li>
                      <BannerSmallContent>
                        <Banner
                          isPreview={isPreview}
                          slotId={banners.shift()}
                        />
                      </BannerSmallContent>
                    </li>
                  )}
                </Fragment>
              );
            })}
          </ul>
      </FeaturedNewsContainer>
    </FeaturedNews>
  ) : (
    <></>
  );
};

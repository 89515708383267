import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const AuthorOpinionContainer = styled.div`
  display: flex;
  justify-content: center;
  picture {
    height: 90px !important;
    width: 90px !important;
    ${breakpoints.phone} {
      height: 45px;
      width: 45px;
    }
    img {
      object-fit: contain;
      border-radius: 50%;
      background: ${Color.greyf1f1f1};
      min-height: 90px;
    }
  }
  .authorData {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    height: 90px;
    align-items: baseline;
    /* justify-content: space-around; */
    padding-top: 10px;
    .author {
      text-transform: uppercase;
      color: ${Color.black000};
      padding-bottom: 8px;
      ${Font.clarinVarSub2Bold};
      font-size: 22px;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        color: ${ColorDark.textLight};
        }
      }
      ${breakpoints.phone} {
        font-size: 20px;
      }
    }
    span {
      font-size: 16px;
      color: ${Color.black000};
      padding-bottom: 0.5rem;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        color: ${ColorDark.textLight};
        }
      }
      ${Font.clarinVarSub2Regular};
      ${breakpoints.phone} {
        font-size: 14px;
      }
    }
    .share {
      div {
        padding-bottom: 0;
        justify-content: unset;
        border-top: none;
        ul {
          li {
            
            a {
              background: ${Color.greyf1f1f1};
              border: none;
              width: 25px;
              height: 25px;
              padding: 0;
              display: flex;
              justify-content: center;
              svg {
                fill: ${Color.black000};
                height: 13px !important;
               
             
              }
              &:hover {
                background: ${Color.black000};
                svg {
                  fill: ${Color.greyf1f1f1};
                }
              }
            }
          }
        }
      }
    }
  }
`;

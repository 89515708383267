import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const TagsContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  flex-direction: column;
  /* padding-bottom: 40px; */
  hr {
    margin: 0;
    border: 0 none;
    &:before {
      content: "";
      height: 1px;
      width: 100%;
      background: #d1d1d1;
      display: block;
      margin: 3px 0;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        background: ${ColorDark.greySeparator};
        }
      }
    }
    &:after {
      content: "";
      height: 1px;
      width: 100%;
      background: #d1d1d1;
      display: block;
      margin-top: 5px;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        background: ${ColorDark.greySeparator};
        }
      }
    }
  }
  span {
    ${Font.sansBold};
    color: ${Color.black};
    font-size: 16px;
    padding: 1rem 0 1.5rem 0;
    line-height: 28px;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      color: ${ColorDark.textLight};
      }
    }
    ${breakpoints.phone} {
      font-size: 17px;
    }
  }
`;

export const Tags = styled.ul.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .tag {
    border-radius: 50px;
    border: 1px solid #e0e0e0;
    padding: 0.7rem;
    a {
      ${Font.sansRegular};
      color: ${Color.grey};
      font-size: 13px;
      line-height: 21px;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        color: ${ColorDark.textLight};  
        }
      }  
    }
  }
`;

import { LinkAbsolute } from "@/shared/LinkAbsolute";
import { AuthorContainer } from "../Authors/style";
import { getStringWithoutHtml } from "@/helpers/getStringWithoutHtml";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

export const AuthorsInfoFooter = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  
  // si no tengo props, busco contexto
  if(Object.keys(props).length === 0 && !!formatContent) {
    
    props = { 
        ...props,        
        listAuthors: formatContent?.storyContainer?.data?.authors ?? [],
        addPathAuthor: formatContent?.storyContainer?.data?.addPathAuthor ?? false,
    };
  }  

  const { listAuthors = [], addPathAuthor = false } = props;

  return (
    <>
      {listAuthors.length > 0 &&
        listAuthors.map((author, index) => {
          const hasImage = Boolean(author.imageUrl);
          
          let imageUrlAuthor = "";                              
          
          if (hasImage) {

          const imageUrl = author.imageUrl ;                    
          const baseUrlImg = "https://www.clarin.com/img";
        
          imageUrlAuthor = imageUrl.includes("images.clarin.com") ? imageUrl.replace("http://images.clarin.com", baseUrlImg) : baseUrlImg + author.imageUrl;
          }


          return (
            <AuthorContainer
              className="bottom-author"
              key={`author${index}`}
              style={{ position: "relative" }}
              hasImage={hasImage}
            >
              {author.slug && <LinkAbsolute href={`/autor/${ (!!addPathAuthor) ? addPathAuthor : ''}${author.slug}.html`} aria-label={author.name}/>}
                {author.imageUrl && (
              <picture>
                  <img
                    width={hasImage ? 60 : 0}
                    height={hasImage ? 60 : 0}
                    src={imageUrlAuthor}
                    alt={author.name}
                  />
              </picture>
                )}
              <div className="author-info">
                <span>{author?.name}</span>
                <p style={{ WebkitLineClamp: 3 }}>
                  {author?.shortBiography ?? getStringWithoutHtml(author?.biography)}{" "}
                  {author?.email && (
                    <a
                      href={`mailto:${author?.email}`}
                      target="_blank"
                      style={{ position: "relative", zIndex: 4 }}
                    >
                      {author?.email}
                    </a>
                  )}
                </p>
                <span className="bioInfo">Bio completa</span>
              </div>
            </AuthorContainer>
          );
        })}
    </>
  );
};

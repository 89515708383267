import { LinkAbsolute } from "@/shared/LinkAbsolute";
import Slider from "@/shared/Slider";
import { HighLightContainer, TimeLine, ItemNew } from "./style";
import ContentIcon from "@/shared/ContentIcon";
import { getDateFormatForHighlightLiveblog, getTimeFormatForHighlightLiveblog } from "helpers";

export const HighlightLiveblogSlider = ({ highlightedParagraphsLiveblog }) => {

  const slides = highlightedParagraphsLiveblog.map((item, i) => (
    <ItemNew className="item" key={`highlightedParagraphs${i}`}>
      <LinkAbsolute
        href={`#highlightedLink${i + 1}`}
        onClick={(e) => {
          e.preventDefault();
          const element = document.getElementById(`highlightedLink${i + 1}`);
          element.scrollIntoView({ behavior: "smooth" });
        }}
      />
      <span className="itemDate">
        {getDateFormatForHighlightLiveblog(item.dateParagraph)}
        <br/>
        {getTimeFormatForHighlightLiveblog(item.dateParagraph)}
      </span>
      <div className="itemContent" dangerouslySetInnerHTML={{ __html: item.value.replace(/<h2/g, '<h3').replace(/<\/h2>/g, '</h3>')}}
      />
    </ItemNew>
  ));

  const getSlider = (quantity, classes) => {
    return <Slider
    items={slides}
    quantityPerItem={quantity}
    loopSlider={false}
    type={"autocompleteSlider"}
    styleClass={classes}    
    arrowLeft={
      <button
        id="slideLeft"
        className="btnSlide left"
        aria-label="Deslizar hacía la izquierda"
      >
        <ContentIcon
          width="18px"
          height="18px"
          fill="#000"
          nameIcon="chevronUp"
          alt="Flecha hacía la izquierda"
        />
      </button>
    }
    arrowRight={
      <button
        id="slideRight"
        className="btnSlide right"
        aria-label="Deslizar hacía la derecha"
      >
        <ContentIcon
          width="18px"
          height="18px"
          fill="#000"
          nameIcon="chevronUp"
          alt="Flecha hacía la derecha"
        />
      </button>
    }
  />
  }

  return (
    <HighLightContainer>
      <h4>Lo más destacado:</h4>
      <div className="bgGradient"></div>
      <div className="lineDay"></div>
      {getSlider(1, "mobile")}
      {getSlider(3, "tablet")}
      {getSlider(4, "desktop")}
    </HighLightContainer>
  );
};

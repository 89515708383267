import TemplateContext from "@/context/Template/TemplateContext";
import { useContext, useEffect, useRef } from "react";
import { PowerBeansBox } from "./style";
import ContentIcon from "@/shared/ContentIcon";
export const Powerbeans = () => {
  const dataContext = useContext(TemplateContext);
  const divRef = useRef();

  useEffect(() => {
    if (!dataContext?.content?.enabledPowerbeans) return;
    const nodoScript = divRef?.current?.querySelector("script");
    if (!!nodoScript) {
      nodoScript.innerHTML = `   
              !function(){
                  var e = document.createElement("link");
                  e.rel = "stylesheet";
                  e.href = "https://dev.powerbeans-ai.com/powerbeans-widget-latest.css";
                  document.head.appendChild(e);                    
                  var t = document.createElement("script");
                  t.src = "https://dev.powerbeans-ai.com/powerbeans-widget-latest.js";
                  document.body.appendChild(t);
                  var r = document.createElement("div");
                  r.id = "powerbeans-widget";
                  r.setAttribute("apiKey","${process.env.NEXT_PUBLIC_API_PUBLIC_KEY_POWERBEANS}");
                  var a = document.currentScript;
                  a.parentNode.insertBefore(r,a.nextSibling);
              }();
          `;
    }
  }, []);
  return !dataContext?.content?.enabledPowerbeans ? (
    <></>
  ) : (
    <PowerBeansBox ref={divRef}>
     {dataContext?.content?.authorNamePowerbeans ? <div className="speaker"><ContentIcon className="iconSpeak" fill="#d80026" nameIcon="speakerVoice" /><p><span>La voz de</span> {dataContext?.content?.authorNamePowerbeans}</p></div> : <></>}  
      <script />
    </PowerBeansBox>
  );
};

import { useEffect, useState } from "react";
import { setTags } from '../helpers/setTags';
import { setBody } from '../helpers/setBody';

const useGetRecommenderRaul = (contentId, tags) => {

    const [values, setValues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [z_control, setZ_control] = useState("");

    const getRecommenderRaul = async () => {        

        let body = setBody(contentId);
        body.nntags = setTags(tags);
        //console.log({ body });
        
        try {
            const response = await fetch(`https://raul.clarin.com/recomendador/root/recomendar`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {                
                throw new Error(`getRecommender: ${response.status} ${response.statusText}`);
            }

            const resp_data = await response.json();
            //console.log({ resp_data });           

            if (resp_data.data && Array.isArray(resp_data.data) && resp_data.data.length) {
                setValues(resp_data.data);
                setIsLoading(false);
                setZ_control(resp_data.z_control.id);
            }

        } catch (error) {
            console.error('Error: fetch api recommender');
        }
    }

    useEffect(() => {
        getRecommenderRaul();
    }, []);

    return {
        values,
        isLoading,
        z_control,
    }
}

export { useGetRecommenderRaul };
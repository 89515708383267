const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const diff = today.getMonth() - birthDate.getMonth();
    if (diff < 0 || (diff === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }
    return age;
}

const getReferrer = () => {
    if (document.referrer.length == 0) {
        return "";
    }
    const referrer = document.referrer.split('/')[2];
    return referrer.replace(/\./g, "_");
}

const getPlatform = () => {
    let platform = "";
    if ('userAgent' in navigator && navigator.userAgent) {
        const _userAgent = navigator.userAgent;
        platform = "desktop";
        if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/i.test(_userAgent)) {
            platform = "mobile";
        }
        if (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(_userAgent)) {
            platform = "tablet";
        }
    }
    return platform;
}

const setBody = (contentId) => {
    let user = JSON.parse(localStorage.getItem("wads_userProfile"));
    if (user && ("value" in user)) user = user.value;

    const defaultValue = "-2";

    const userId = (user && (typeof user.id !== 'undefined')) ? user.id : defaultValue;
    const userSexo = (user && (typeof user.genero !== 'undefined') && user.genero !== "") ? user.genero : defaultValue;
    const userEdad = (user && (typeof user.fechaNacimiento !== "undefined") && user.fechaNacimiento !== "") ? getAge(user.fechaNacimiento) : defaultValue;
    const userTipo_lector = (user && (typeof user.subscriptionStatus !== 'undefined') && user.subscriptionStatus !== "") ? user.subscriptionStatus : "";

    const body = {
        pase: userId,
        content: contentId,
        sexo: userSexo,
        edad: userEdad,
        tipo_lector: userTipo_lector,
        nnsite: "clarin",
        referrer: getReferrer(),
        platform: getPlatform(),
    }

    return body;
}

export {
    setBody,
}
import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";



export const ItemNew=styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -6px;
  /* max-width: 200px; */
  position: relative;
  row-gap: 6px;
  margin-right: 63px;
  justify-content: space-around;
  :hover {
    opacity: 0.75;
  }
  ${breakpoints.phone} {
    margin-right: 0;
    height: 88px;
   
      }
  .itemDate {
    color: ${Color.mainColor};
    ${Font.sansSemiBold};
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    position:relative;
    margin-top: 5px;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      color: ${ColorDark.mainColor};
      }
    }

  }
  .itemContent {    
          height: 74px;
          display: -webkit-box;
          max-width: 200px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          ${breakpoints.darkMode} {
            [data-theme='dark'] & {
            color: ${ColorDark.textLight};
            }
          }
          ${breakpoints.phone} {
            max-width: 70%;
            -webkit-line-clamp: 3;
          }
          p , strong, h2, h3{
            font-size: 17px;
            ${Font.clarinVarSub2Regular};
            line-height: 18px;
            text-align: center;
            ${breakpoints.phone} {
              line-height: 17px;
            }
          }
        }
        a{
          ::before {
            content: "●";
            color: ${Color.mainColor};
            font-size: 18px;
            position: absolute;
            top: -12px;
            left:49%;
            ${breakpoints.phone} {
              top: -14px;
            }
          }
        }
        
`




export const HighLightContainer = styled.div`
  background-color: ${Color.greyF5F5F5};
  width: 100%;
  position: relative;
  column-gap: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height:140px;
  transform: translateY(-32px);
    padding: 28px 20px 16px;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color: ${ColorDark.darkGrey};
      }
    }
    ${breakpoints.phone} {
      height:120px;
      transform: translateY(-17px);
      justify-content: center;
      margin-top: 70px;
    }
  .desktop {
    ${breakpoints.phone},  ${breakpoints.tablet}  {
      display: none;
    }
    ${breakpoints.tablet} {
      display: none;
    }
  }
  .mobile {
    ${breakpoints.desktop} {
      display: none;
    }
    ${breakpoints.tablet} {
      display: none;
    }
  }
  .tablet {
    ${breakpoints.desktop} {
      display: none;
    }
    ${breakpoints.phone} {
      display: none;
    }
  }

ul{
  width:auto!important;
  padding-left: 20px;
  ${breakpoints.phone} {
    padding-left: 0;
      }
  li{
    display:flex;
  } 
}
  h4 {
    ${Font.clarinVarSub2Bold};
    font-size: 19px;
    line-height:19px;
    max-width: 126px;
    color: ${Color.grey};
    ${breakpoints.phone} {
      display:none;
    }
  }
  .bgGradient{
    width: 85px;
    height: 118px;
    position: absolute;
    background-color: #F5F5F5;
    right: 0;
    top: 0;
    box-shadow: -24px 0px 5px 0px rgb(245 245 245 / 85%);
    z-index: 3;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color: ${ColorDark.darkGrey};
      box-shadow: -24px 0px 5px 0px rgb(41 41 41 / 85%);
      }

    }
    ${breakpoints.phone} {
      width:40px;
    }
  }
  .btnSlide {
    
    border: 0 none;
    background-color: inherit;
    position: absolute;
    top: 60px;
    z-index:8;
    cursor: pointer;
    padding-top: 4px;
    ${breakpoints.darkMode} {

      [data-theme='dark'] & {
      border:none;
      }

    }
    ${breakpoints.phone} {
      top:40px;
    }
    :hover {
      background-color: #efefef;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        background-color: ${ColorDark.greySeparator};
        border:none;
        }
      }
    }
    &.right {
      right: 22px;
      svg {
        transform: rotate(-90deg);
        ${breakpoints.darkMode} {
          [data-theme='dark'] & {
          fill: ${ColorDark.textLight};
          }
        }
      }
      ${breakpoints.phone} {
        right: 10px;
      }
    }
    &.left {
      left: 160px;
      svg {
        transform: rotate(90deg);
        ${breakpoints.darkMode} {
          [data-theme='dark'] & {
          fill: ${ColorDark.textLight};
          }
        }
      }
      ${breakpoints.tablet} {
        left: 140px;
      }
      ${breakpoints.phone} {
        left: 5px;
      }
    }
  }
  .lineDay {
    height: 0;
    min-width: 1060px;
    border-top: 1px dotted #7d7c7c;
    position: absolute;
    top: 16px;
    right: 48px;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      border-top: 1px dotted ${ColorDark.textLight};
      }
    }
    ${breakpoints.phone} {
      min-width: -webkit-fill-available;
      min-width: 0;
      top: 16px;
      left: 50px;
      }
    ${breakpoints.tablet} {
      min-width: 700px;
      top: 16px;
      left: 193px;
      }
    }
    ${breakpoints.phone} {
    &:before{
      content:"Lo más destacado: ";
      ${Font.clarinVarSub2Bold};
        font-size: 19px;
        line-height:19px;
        position:absolute;
        top:-28px;
    }
    }
`

export const TimeLine = styled.div`
  height: 0;
  min-width: 1200px;
  border-top: 1px dotted #7d7c7c;
  ${breakpoints.tablet} {
    min-width: 900px;
  }
  ${breakpoints.phone} {
    min-width: 800px;
  }
`

import { useRef, useEffect } from "react";

export const Trinity = () => {
    
    /** ID         // DOMINIO
     *  2900007258 // www.clarin.com
     *  2900011724 // tld-2udck713.cloud.agea.com.ar
     *  2900011725 // web-clarinsandbox.lilax.io
     */
    const idPlayer = '2900007258';

    const divRef = useRef();

    useEffect(() => {        
        const nodoTrinity = document.createElement('script');
        const paramLocationHref = encodeURIComponent(window.location.href);
        nodoTrinity.src = `https://trinitymedia.ai/player/trinity/${idPlayer}/?pageURL=${paramLocationHref}`;
        nodoTrinity.async = true;        
        divRef.current.parentNode.insertBefore(nodoTrinity,divRef.current);       
    }, []);    

    return (
        <div ref={divRef}/>
    );
}
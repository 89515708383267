import ContentPayWallExclusive from "@/shared/ContentPayWallExclusive";
import {
  JWPlayerContainer,
  JWText,
} from "@/templates/Clarin/Nota/components/RenderBody/components/Video/style";
import {
  AuthorsSpecial
} from "@/templates/Clarin/Nota/components";
import React from "react";
import JWPlayer from "@/shared/JWPlayer";
import ContentImage from "@/shared/ContentImage";
import MAM from "@/shared/MAM";
import {
  StoryHeaderContainer,
} from "./style";
import { HighlightLiveblogSlider } from "../HighlightLiveblogSlider";

export const StoryHeader = ({
  authors,
  dataImage,
  dataVideo,
  freeHtml,
  hasRelated,
  isLiveblog = false,
  mam,
  newsSeo,
  onClickButtonSlider,
  payWallAccessControl,
  sectionIdJWPlayerLead,
  specialAuthors,
  summary,
  summaryTag = "h2",
  title,
  titleEnable,
  volanta,
  relatedVideosLoop,
  preloadImg = true,
  highlightedParagraphsLiveblog,
  disabledTimelineLiveblog,
  type = ""
}) => {
  const RenderSumaryTag = summaryTag;

const megaAndVideo = type === "mega" && dataVideo
  return (
    <StoryHeaderContainer className="shared-header">
      {(highlightedParagraphsLiveblog?.length >= 4 && !disabledTimelineLiveblog) && (<HighlightLiveblogSlider highlightedParagraphsLiveblog={highlightedParagraphsLiveblog} />)}
      {specialAuthors && (
        <AuthorsSpecial
          className="social"
          listAuthors={authors}
          volanta={volanta}
        />
      )}
      {titleEnable && (
        <div className={`mt ${megaAndVideo ? "mt-video": ""}`}>
          {isLiveblog && (
            <>
              <span className="live">En vivo</span>
              {volanta && <span>{volanta}</span>}
            </>
          )}
          {payWallAccessControl && (
            <ContentPayWallExclusive sectionClass={"nota"} />
          )}
          {title && (
            <h1 className={`storyTitle ${megaAndVideo ? "storyTitle-video": ""}`}>
              {title
                ?.replace('"', "“")
                .replace('"', "”")
                .replace('"', "“")
                .replace('"', "”")
                .replace('"', "“")
                .replace('"', "”")}
            </h1>
          )}
          {summary && (
            <RenderSumaryTag
              className="storySummary"
              suppressHydrationWarning
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          )}
          {newsSeo &&
            newsSeo.map((news, index) => (
              <h2 className="storySummary" key={`newsSeo${index}`}>
                <a href={news?.value?.url}>{news?.title}</a>
              </h2>
            ))}
        </div>
      )}
      {hasRelated && (
        <div className={`image-container ${megaAndVideo ? "image-container-video": ""}`}>
          {freeHtml?.value?.html ? (
            <div
              className="freeHtml"
              suppressHydrationWarning
              dangerouslySetInnerHTML={{ __html: freeHtml.value.html }}
            />
          ) : relatedVideosLoop?.videoFiles ? (
            <VideoLoop link={relatedVideosLoop.videoFiles["mp4"]} />
          ) : dataVideo ? (
            <div className="player-container">
              {/* se elimina el componente JWPlayerContainer y se agrega la clase jwplayer-container para que se le de estilos desde ahí porque liveblog no usaba el componente y normal si */}
              <JWPlayerContainer className="jwplayer-container border-video">
                <JWPlayer
                  id={`jwplayer-${dataVideo.entityId}`}
                  //autostart={false}
                  entityId={dataVideo.entityId}
                  playerScript={sectionIdJWPlayerLead}
                  thumbnail={dataVideo.thumbnail}
                  title={dataVideo?.title?.replace(/<\/?[^>]+(>|$)/g, "") || ""}
                  type="apertura"
                />

                <JWText className="videoText story-header">
                  <p className="jwt-text-title">Video</p>
                  {dataVideo?.title && <span dangerouslySetInnerHTML={{ __html: dataVideo?.title }} /> }
                </JWText>
              </JWPlayerContainer>
            </div>
          ) : (
            <>
              <picture onClick={() => onClickButtonSlider(0)}>
                <ContentImage
                  alt={title}
                  data={dataImage}
                  dataHero={preloadImg}
                />
                <span className="picture-open">
                  <button className="open-image" aria-label="Open Image" />
                </span>
                <span className="bkg"></span>
                {!isLiveblog && (
                  <span className="epigraphe">{dataImage.epigraphe}</span>
                )}
              </picture>
              {isLiveblog && (
                <span className="epigraphe">{dataImage.epigraphe}</span>
              )}
            </>
          )}
        </div>
      )}
      {mam?.enabled && <MAM sport={mam.sport} match={mam._id} type="news" />}
    </StoryHeaderContainer>
  );
};

import dynamic from "next/dynamic";

const Templates = {
  blockquote: dynamic(() => import("./Blockquote")),
  embed: dynamic(() => import("./FreeHtml")),
  featuresRecipe: dynamic(() => import("./FeaturesRecipe")),
  freehtml: dynamic(() => import("./FreeHtml")),
  image: dynamic(() => import("./ImageBody")),
  ingredients: dynamic(() => import("./Ingredients")),
  lilanews: dynamic(() => import("./RelatedNewsBody")),
  gallery: dynamic(() => import("./RelatedNewsBody")),
  recipe: dynamic(() => import("./RelatedNewsBody")),
  text: dynamic(() => import("./TextBody")),
  video: dynamic(() => import("./Video")),
  signatureRecipe: dynamic(() => import("./SignatureRecipe")),
  widgets_opta: dynamic(() => import("./OptaWidget")),
};

export default Templates;

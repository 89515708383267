import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";
export const AuthorTop = styled.div`
  &.speakable-author span {
    display: block;
    ${Font.sansSemiBold};
    font-size: 16px;
    /* text-transform: uppercase; */
    letter-spacing: 0px;
    line-height: 21px;
    padding-right: 0.3rem;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
    &.bioInfo {
      font-size: 14px;
    }
  }
`;

export const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ hasImage }) => (hasImage ? "6px" : "0")};

  .multiple-authors p {
    display: flex;
    flex-direction: row;
    span {
      padding-right: 0;
    }
  }

  picture {
    background: var(--back-color);
    border-radius: 45px;
    max-height: 60px;
    overflow: hidden;
    min-height: 60px;
    object-fit: cover;
    margin-right: ${({ hasImage }) => (hasImage ? "15px" : "0")};
    min-width: ${({ hasImage }) => (hasImage ? "60px" : "0")};

    img {
      min-width: ${({ hasImage }) => (hasImage ? "60px" : "0")};
      max-width: ${({ hasImage }) => (hasImage ? "60px" : "0")};

      height: 60px;
      background: ${Color.greyf1f1f1};
    }
  }
  a {
    color: ${Color.black000};
    row-gap: 0.2rem;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
    .author-place {
      ${Font.sansRegular};
      font-size: 11px;
      line-height: 18px;
      color: ${Color.black};
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.textLight};
        }
      }
    }
  }
  span {
    display: block;
    ${Font.sansSemiBold};
    font-size: 16px;
    /* text-transform: uppercase; */
    letter-spacing: 0px;
    line-height: 21px;
    padding-right: 0.3rem;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
    &.bioInfo {
      font-size: 14px;
    }
  }
  p {
    ${Font.sansRegular};
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.15px;
    color: ${Color.black};

    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
    ${breakpoints.phone} {
      font-size: 15px;
      line-height: 20px;
    }
  }
`;

import { Fragment } from "react";
import Templates from "./components";
import BannerDistribution from "./components/BannerDistribution";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

export const RenderBody = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};

  // si no tengo props, busco contexto
  if (Object.keys(props).length === 0 && !!formatContent) {
    const storyData = formatContent?.storyContainer?.data;
    props = {
      ...props,
      body: storyData?.body ?? [],
      onClickButtonSlider: storyData?.onClickButtonSlider ?? ((index) => { }),
      positionImage:
        (storyData?.hasRelated && !storyData?.freeHtml?.value?.html && !storyData?.dataVideo)
          ? 1
          : 0,
      isPreview: rest?.isPreview ?? false,
      innerWidth: storyData?.innerWidth,
      sectionIdJWPlayerEmbed: storyData?.sectionIdJWPlayerEmbed,
      sectionIdJWPlayerEmbedUpright: storyData?.sectionIdJWPlayerEmbedUpright,
      withBodyBanner: storyData?.withBodyBanner ?? true,
      adsPath: storyData?.adsPath,
      notaId: formatContent?._id,
    };
  }

  const {
    body = [],
    onClickButtonSlider = (index) => { },
    positionImage = 0,
    isPreview = false,
    innerWidth,
    sectionIdJWPlayerEmbed,
    sectionIdJWPlayerEmbedUpright,
    withBodyBanner = true,
    adsPath,
    notaId } = props;

  const bodyNewsLength = Array.isArray(body) ? body.length : 0;
  const paragraph = body.filter((element) => (element?.type === "text" && !element?.value?.startsWith("<h2>") && !element?.value?.startsWith("<h3>")) || element?.type === "freehtml");

  if (bodyNewsLength > 0) {

    let auxPositionImage = positionImage;
    const fnOfImages = {};

    return body.map((element, index) => {
      const TargetComponent = Templates[element?.type];

      if (element?.type === "image") {

        fnOfImages['onClickButtonSlider'] = onClickButtonSlider;
        fnOfImages['positionImage'] = auxPositionImage;
        auxPositionImage++;

      }

      return (
        <Fragment key={`renderBody${index}`}>
          {TargetComponent && <TargetComponent content={element} {...fnOfImages} sectionIdJWPlayerEmbed={sectionIdJWPlayerEmbed} sectionIdJWPlayerEmbedUpright={sectionIdJWPlayerEmbedUpright} notaId={notaId} elementBodyId={`element-body-${index}`}/>}
          {(innerWidth && withBodyBanner) ? (
            <BannerDistribution
              innerWidth={innerWidth}
              isPreview={isPreview}
              element={element}
              paragraph={paragraph}
              adsPath={adsPath}
            />
          ) : (
            <></>
          )}
        </Fragment>
      );
    });
  }

  return (innerWidth && withBodyBanner) ? (
    <BannerDistribution
      innerWidth={innerWidth}
      isPreview={isPreview}
      element={null}
      paragraph={[]}
    />
  ) : (
    <></>
  );
};

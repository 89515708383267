export const sizeBanner = {
  horizontal2: {
    "sizes": [[320, 50], [320, 90], [320, 100], [300, 250], [300, 600], [970, 250], [950, 200], [728, 90], [950, 300], [970, 300], [950, 250], [970, 200], "fluid"],
    "mapping": [
      {
        "viewport": [1280, 0],
        "msizes": [[970, 250], [950, 200], [950, 300], [970, 300], [950, 250], [970, 200]]
      },
      {
        "viewport": [1024, 0],
        "msizes": [[728, 90]]
      },
      {
        "viewport": [0, 0],
        "msizes": [[320, 50], [320, 90], [320, 100], [300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  horizontal3: {
    "sizes": [[1,1],[320, 50], [320, 90], [320, 100], [300, 250], [300, 600], [970, 250], [950, 200], [728, 90], [950, 300], [970, 300], [950, 250], [970, 200], [1316, 300], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1,1],[728, 90], [970, 250], [950, 200], [950, 300], [970, 300], [950, 250], [970, 200], [1316, 300]]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1,1],[320, 50], [320, 90], [320, 100], [300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true,
    "lazy" : {
      "fetchMarginPercent": 10,                         
      "renderMarginPercent": 0.4,                         
      "mobileScaling": 0.1, 
    }
  },
  auspicio: {
    "sizes": [[1,1],[300, 50], [320, 50]],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1,1],[300, 50]]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1,1],[320, 50]]
      }
    ],
    "active": true
  },
  caja1: {
    "sizes": [[1,1],[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  caja2: {
    "sizes": [[1,1],[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  caja3: {
    "sizes": [[1,1],[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  caja4: {
    "sizes": [[1,1],[320, 50], [320, 90], [320, 100], [300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1,1],[320, 50], [320, 90], [320, 100], [300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  caja5: {
    "sizes": [[1,1],[320, 50], [320, 90], [320, 100], [300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1,1],[320, 50], [320, 90], [320, 100], [300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  caja6: {
    "sizes": [[1,1],[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  caja0b: {
    "sizes": [[1,1],[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1,1],[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  inread: {
    "sizes": [[1, 1], [600, 338], [600, 450], [600, 400], [300, 250], [315, 225], [320, 180], [320, 240], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1, 1], [600, 338], [600, 450], [600, 400], [300, 250]]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1, 1], [315, 225], [320, 180], [320, 240], "fluid"]
      }
    ],
    "active": true
  },
  inread2: {
    "sizes": [[1, 1], [600, 338], [600, 450], [600, 400], [300, 250], [315, 225], [320, 180], [320, 240], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1, 1], [600, 338], [600, 450], [600, 400], [300, 250]]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1, 1], [315, 225], [320, 180], [320, 240], "fluid"]
      }
    ],
    "active": true,
    "lazy" : {
      "fetchMarginPercent": 10,                         
      "renderMarginPercent": 0.4,                         
      "mobileScaling": 0.1, 
    }
  },
  inread3: {
    "sizes": [[1, 1], [600, 338], [600, 450], [600, 400], [300, 250], [315, 225], [320, 180], [320, 240], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1, 1], [600, 338], [600, 450], [600, 400], [300, 250]]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1, 1], [315, 225], [320, 180], [320, 240], "fluid"]
      }
    ],
    "active": true,
    "lazy" : {
      "fetchMarginPercent": 10,                         
      "renderMarginPercent": 0.4,                         
      "mobileScaling": 0.1, 
    }
  },
  inread4: {
    "sizes": [[1, 1], [600, 338], [600, 450], [600, 400], [300, 250], [315, 225], [320, 180], [320, 240], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[1, 1], [600, 338], [600, 450], [600, 400], [300, 250]]
      },
      {
        "viewport": [0, 0],
        "msizes": [[1, 1], [315, 225], [320, 180], [320, 240], "fluid"]
      }
    ],
    "active": true
  }
};
import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

const NewsletterContainer = styled.div`
  position: relative;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  padding: 0 11px 8px 11px;
  margin: 2.5rem 0;
  ${breakpoints.darkMode} {
    [data-theme='dark'] & {
    border: 1px solid ${ColorDark.textLight};
    }
  }
  ${breakpoints.phone} {
    margin: 0 0 2.5rem 0;

  }
  .sepFullAdaptive{
    margin-top: 0 ;
  }
  span {
    color: ${Color.black};
    font-size: 20px;
    font-weight: 700;
    font-family: ${Font.sansRegular};
    padding: 0.6rem 0;
  }
  .inner-card-m {
    min-height: 90px;
    padding: 0.5rem 0;
  }

  .related-content {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    column-gap: 0.5rem;
    justify-content: left;
    min-height: 90px;

    img {
      object-fit: cover;
      max-width: 90px;
      max-height: 90px;
      ${breakpoints.phone} {
        max-width: 100px;
        max-height: 100px;
      }
    }
    p {
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      color: ${Color.black000};
      ${Font.sansLight};
      display: -webkit-box;
      overflow: hidden;
      align-self: start;
      line-height: 22px;
      font-size: 17px;
      padding-bottom: 17px;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        color: ${ColorDark.textLight};
        }
      }
      ${breakpoints.phone} {
        line-height: 20px;
        font-size: 15px;
        letter-spacing: -0.15px;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
    }
  }
  .body-title {
    display: none;
  }
  .button-link {
    ${Font.sansBold};
    font-size: 12px;    
    color: ${Color.black};
    text-align: center;
    line-height: 12px;
    border: 1px solid #000000;
    border-radius: 50px;
    opacity: 1;
    display: flex;
    padding: 8px 17px;
    justify-content: center;
    width: max-content;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      color: ${ColorDark.textLight};
      border: 1px solid ${ColorDark.textLight};
      }

    }
  }
  .separator-title {
    hr {
      display: none;
    }
    .sep {
      font-family: ${Font.sansBold};
      font-size: 16px;
      line-height: 28px;
      ${breakpoints.phone} {
        font-size: 17px;
      }
    }
  }
`;

export default NewsletterContainer;

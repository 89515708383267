import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom/client";
import { RelatedNews } from "../RelatedNews";
import { useRouter } from "next/router";

export const DynamicRelatedNews = ({ limit, provider, section }) => {
  const router = useRouter();
  const [mostViewed, setMostViewed] = useState(null);
  const parentBannerDivRef = useRef(null);
  const observerRef = useRef(null);
  const relatedNewsContainerRef = useRef(null);

  const fetchMostViewed = async () => {
    try {
      const response = await fetch(`/api/viewed/${limit}/${provider}?section=${section}`);
      const { data, code } = await response.json();

      if (code === 200 && data?.length > 0) {
        setMostViewed(data[0]);
      }
    } catch (error) {
      console.error("Error fetching most viewed:", error);
    }
  };

  const observeBannerDiv = () => {
    observerRef.current = new MutationObserver(() => {
      const target = document.getElementById("div-gpt-ad-inread");
      if (target) {
        parentBannerDivRef.current = target.parentElement;
        observerRef.current.disconnect();
      }
    });

    observerRef.current.observe(document.body, { childList: true, subtree: true });
  };

  const renderRelatedNews = () => {
    if (!parentBannerDivRef.current || !mostViewed) return;

    if (!relatedNewsContainerRef.current) {
      relatedNewsContainerRef.current = document.createElement("div");
      parentBannerDivRef.current.parentNode.insertBefore(
        relatedNewsContainerRef.current,
        parentBannerDivRef.current.nextSibling
      );
    }

    const root = ReactDOM.createRoot(relatedNewsContainerRef.current);
    root.render(
      <RelatedNews
        value={mostViewed}
        titleNewsEmbed="Mirá también"
        asPath={router.asPath}
        contentList={"mira_tambien_dynamic"}
      />
    );

    return root;
  };

  useEffect(() => {
    observeBannerDiv();

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    const root = renderRelatedNews();

    return () => {
      if (root) root.unmount();
      if (relatedNewsContainerRef.current) {
        relatedNewsContainerRef.current.remove();
        relatedNewsContainerRef.current = null;
      }
    };
  }, [mostViewed, parentBannerDivRef.current]);

  useEffect(() => {
    fetchMostViewed();
  }, [limit, provider, section]);

  return null;
};

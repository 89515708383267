import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const Social = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  flex-direction: row;
  grid-column-gap: 0.5rem;
  padding-bottom: 30px;
  align-items: center;
  min-width: 50%;
  justify-content: right;
  ${breakpoints.phone} {
    border-top: 1px solid #e0e0e0;
    width: 100%;
    padding: 1rem 0 1rem 0;
    justify-content: space-between;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      border-top: 1px solid ${ColorDark.greySeparator};
      }

    }
  }
  .socialContainer {
    display: flex;
    flex-direction: row;
    grid-column-gap: 0.5rem;

    button,
    a:not(.number) {
      cursor: pointer;
      border: none;
      border-radius: 35px;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      border: 1px solid ${Color.grey};
      background: none;
      justify-content: center;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        border: 1px solid ${ColorDark.textLight};
        }

      }
      &:hover {
        background: ${Color.grey};
        svg {
          fill: white;
          height: 17.5px;
          width: 21.5px;
        }
      }

      svg {
        fill: ${Color.grey};
        height: 17.5px;
        width: 21.5px;
        ${breakpoints.darkMode} {
          [data-theme='dark'] & {
          fill: ${ColorDark.textLight};
          }
        }
      }
    }
  }

  .comments-number {
    border: none;
    background: none;
    width: 35px;
    height: 35px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${breakpoints.darkMode} {

      [data-theme='dark'] & {
      img {
        background: url("/img/comments-dark.svg") no-repeat;
        height: 35px;
        width: 35px;
        background-size: contain;
      }
      a {
        color: ${ColorDark.textLight}!important;
      }
    }
    }
    &:hover {
      color: ${Color.white};
      background: url("/img/comments-hover.svg") no-repeat;
      height: 35px;
      width: 35px;
      background-size: contain;

      a.number {
        color: #fff;
      }
    }
    a.number {
      color: ${Color.grey};
      ${Font.sansBold};
      font-size: 14px;
      position: absolute;
      top: 8px;
    }
  }
`;

import { BannerSmallContent, BannerCollapse } from "@/shared/ContentBanner";
import Banner from "@/widgets/Banner/Templates/Banner";
import BannerLazy from "@/widgets/Banner/Templates/BannerLazy";
import { sizeBanner } from "@/shared/DFP/News/mapping";

const BannerDistribution = ({
  element,
  innerWidth,
  isPreview = false,
  paragraph,
  adsPath
}) => {
  const listBanner = [];

  const isEqualToParagrahByPosition = (position) => {
    if(paragraph?.[position]?._id || element?._id){
      return paragraph?.[position] == element || paragraph?.[position]?._id == element?._id;
    }
    return paragraph?.[position] == element
  }  
  const isEqualToParagrahByLength = (length) => {
    if (paragraph?.[paragraph.length - 1]?._id || element?._id) {
      return paragraph.length <= length && (paragraph?.[paragraph.length - 1] == element || paragraph?.[paragraph.length - 1]?._id == element?._id);
    } 
    return paragraph.length <= length && paragraph?.[paragraph.length - 1] == element    
  }

  if (innerWidth > 1023) {
    if (isEqualToParagrahByPosition(2) || isEqualToParagrahByLength(2) || paragraph.length == 0) {
      listBanner.push(
        <BannerCollapse className="bannerCollapse" key={`inread`}>
          <BannerLazy isPreview={isPreview} slotId={"inread"} adsPath={adsPath} mapping={sizeBanner.inread} />
        </BannerCollapse>
      );
    }
    if (isEqualToParagrahByPosition(paragraph.length - 4) || isEqualToParagrahByLength(4) || paragraph.length == 0) {
      listBanner.push(
        <BannerCollapse className="bannerCollapse" key={`inread4`}>
          <BannerLazy isPreview={isPreview} slotId={"inread4"} adsPath={adsPath} mapping={sizeBanner.inread4} />
        </BannerCollapse>
      );
    }
  } else if (innerWidth > 0 && innerWidth <= 1023) {
    if (isEqualToParagrahByPosition(2) || isEqualToParagrahByLength(2) || paragraph.length == 0) {
      listBanner.push(
        <BannerCollapse className="bannerCollapse" key={`inread`}>
          <BannerLazy isPreview={isPreview} slotId={"inread"} adsPath={adsPath} mapping={sizeBanner.inread} />
        </BannerCollapse>
      );
    }
    if (isEqualToParagrahByPosition(5) || isEqualToParagrahByLength(5) || paragraph.length == 0) {
      listBanner.push(
        <BannerSmallContent key={`caja0`}>
          <Banner isPreview={isPreview} slotId={"caja0"} />
        </BannerSmallContent>
      );
    }
    if (isEqualToParagrahByPosition(8) || isEqualToParagrahByLength(8) || paragraph.length == 0) {
      listBanner.push(
        <BannerCollapse className="bannerCollapse" key={`caja1`}>
          <BannerLazy isPreview={isPreview} slotId={"caja1"} adsPath={adsPath} mapping={sizeBanner.caja1}/>
        </BannerCollapse>
      );
    }
    if (isEqualToParagrahByPosition(11) || isEqualToParagrahByLength(11) || paragraph.length == 0) {
      listBanner.push(
        <BannerCollapse className="bannerCollapse" key={`inread4`}>
          <BannerLazy isPreview={isPreview} slotId={"inread4"} adsPath={adsPath} mapping={sizeBanner.inread4} />
        </BannerCollapse>
      );
    }
    if (isEqualToParagrahByPosition(14) || isEqualToParagrahByLength(14) || paragraph.length == 0) {
      listBanner.push(
        <BannerCollapse className="bannerCollapse" key={`auspicio`}>
          <BannerLazy key={`auspicio`} isPreview={isPreview} slotId={"auspicio"} adsPath={adsPath} mapping={sizeBanner.auspicio}/>
        </BannerCollapse>
      );
    }
    if (isEqualToParagrahByPosition(17) || isEqualToParagrahByLength(17) || paragraph.length == 0) {
      listBanner.push(
        <BannerCollapse className="bannerCollapse" key={`caja2`}>
          <BannerLazy isPreview={isPreview} slotId={"caja2"} adsPath={adsPath} mapping={sizeBanner.caja2}/>
        </BannerCollapse>
      );
    }
    if (isEqualToParagrahByPosition(20) || isEqualToParagrahByLength(20) || paragraph.length == 0) {
      listBanner.push(
        <BannerCollapse className="bannerCollapse" key={`caja3`}>
          <BannerLazy isPreview={isPreview} slotId={"caja3"} adsPath={adsPath} mapping={sizeBanner.caja3}/>
        </BannerCollapse>
      );
    }
    if (paragraph[paragraph.length - 1] == element || paragraph.length == 0) {
      listBanner.push(
        <BannerCollapse className="bannerCollapse" key={`caja4`}>
          <BannerLazy isPreview={isPreview} slotId={"caja4"} adsPath={adsPath} mapping={sizeBanner.caja4} />
        </BannerCollapse>
      );
    }
  }
  return <>{listBanner}</>;
};

export default BannerDistribution;
import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const StoryHeaderContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &.noRelated {
    margin-bottom: 15px;
  }
  &.notaVideo {
    display: flex;
    flex-direction: column-reverse;

    .image-container {
      margin-top: 0px !important;
      margin-bottom: 2px !important;
      ${breakpoints.phone} {
        margin-top: 10px !important;
      }
    }

    .mt {
      margin-bottom: px;
      ${breakpoints.phone} {
        margin-bottom: 20px;
        margin-top: -20px;
      }
    }
    .border-video {
      border: none;
      padding: 0;
      .jwplayer {
        width: 100% !important;
        left: 0rem;
        top: -2rem;
      }
    }
    .videoText {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      margin-top: -35px;

      span {
        font-size: 15px;
        ${Font.sansRegular};
        line-height: 17px;
        color: #555;
        text-align: left;
      }
    }
  }
  .image-container {
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .icon-paywall-box {
    padding-bottom: 17px;
  }
  .player-container {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    video {
      position: absolute;
    }
    .border-video {
      border: none;
      padding: 0;
      .jwplayer {
        width: 100% !important;
        left: 0rem;
        top: -2rem;
      }
    }
    .jw-controls {
      background: none;
    }
    .jw-title.jw-reset-text {
      display: none;
    }
    .jw-svg-icon-play path {
      display: none;
    }
    .jw-svg-icon-play {
      background-image: url("/img/jwplayer/play.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-color: white;
      border-radius: 50%;
    }
    .jw-display {
      flex-direction: unset;
      justify-content: unset;
    }
    .jw-display-container {
      text-align: unset;
      .jw-display-icon-container {
        position: relative;
        float: none;

        .jw-svg-icon {
          width: 40px;
          height: 40px;
        }
      }
    }
    .border-video{
      ${breakpoints.tablet}{
        padding-top: 56.25%!important;
      }
    }
  }

  picture {
    width: 100%;
    height: auto;
    object-fit: cover;
    position: relative;

    img {
      width: 100%;
    }
  }
  .bkg {
    background-image: none;
    ${breakpoints.mobileFirst} {
      position: absolute;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.55)
      );
      bottom: 3px;
      height: 50px;
      width: 100%;
      left: 0;
    }
  }
  .epigraphe {
    ${Font.sansRegular};
    font-size: 15px;
    letter-spacing: -0.15px;
    line-height: 20px;
    color: ${Color.grey};

    ${breakpoints.mobileFirst} {
      color: ${Color.white};
      position: absolute;
      bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      display: flex;
      padding: 0 0.5rem;
      justify-content: center;
      text-align: center;
    }
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
  }

  .mt {
    bottom: 70px !important;
    ${breakpoints.phone} {
      bottom: 0 !important;
    }
    .storyTitle {
      color: ${Color.black000};
      position: relative;
      ${Font.clarinVarSub2Bold};
      font-size: 42px;
      line-height: 48px;
      padding-bottom: 30px;
      font-weight: 700;
      letter-spacing: -0.42px;
      ${breakpoints.phone} {
        font-size: 28px;
        line-height: 33px;
        letter-spacing: -0.28px;
      }
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.textLight}!important;
        }
      }
    }
    .storySummary {
      color: ${Color.grey};
      ${Font.sansRegular};
      font-size: 22px;
      line-height: 36px;
      letter-spacing: -0.22px;
      text-decoration: none;
      ${breakpoints.phone} {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.18px;
        padding-bottom: 13px;
      }
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.textLight};
        }
      }
    }
    a {
      color: ${Color.mainColor};
      &:before {
        background: ${Color.mainColor};
        border-radius: 4px;
        content: " ";
        display: inline-block;
        height: 4px;
        width: 4px;
        margin-right: 6px;
        transition-duration: 0.3s;
        vertical-align: middle;
      }
      &:hover {
        color: ${Color.black};
      }
    }
    ul li {
      &:before {
        background: ${Color.grey};
        border-radius: 4px;
        content: " ";
        display: inline-flex;
        height: 4px;
        width: 4px;
        margin-right: 6px;
        transition-duration: 0.3s;
        vertical-align: middle;
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            background: ${ColorDark.textLight};
          }
        }
      }
    }
  }
  .videoText {
    ${breakpoints.phone} {
      padding: 0px 12px;
    }
  }
  div.mt-video {
    position: unset !important;
    transform: none !important;
    justify-content: flex-start !important;
    padding-top: 0 !important;
    margin-top: -20px;
    h1.storyTitle-video {
      color: black;
      width: 100%;
      text-align: left;
    }
  }
  div.image-container-video {
    padding-bottom: 0 !important;
  }
`;

import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const JWPlayerContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: relative;
  width: 100%;
  padding-top: ${(props) => props.isEspecial ? "120.47%" : "56.25%" } !important;
  margin-bottom: 1rem !important;
  &.border-video {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 2rem;
    margin: 17px 0;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        border: 1px solid ${ColorDark.textLight};
      }
    }
    ${breakpoints.tablet} {
      padding: 1rem;
      padding-top: 90.25% !important;
    }
    ${breakpoints.phone} {
      padding: 1rem;
      padding-top: 56.25% !important;
    }
    .jwplayer {
      width: calc(100% - 4rem) !important;
      left: 2rem;
      top: 2rem;

      ${breakpoints.phone} {
        width: calc(100% - 2rem) !important;
        left: 1rem;
        top: 1rem;
      }
    }
  }

  .jwplayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    .jw-controls {
      background: none;
    }

    .jw-wrapper.jw-reset {
      margin: auto;
      height: min-content;
      display: flex;
      justify-content: center;
    }
  }

  .jw-title.jw-reset-text {
    display: none;
  }
  .jw-controls {
    background: none;
  }

  .jw-display {
    flex-direction: unset;
    justify-content: unset;
  }
  .jw-display-container {
    text-align: unset;
    .jw-display-icon-container {
      float: left;
      position: relative;
      margin: 0;
      padding: 0;

      .jw-svg-icon {
        width: 40px;
        height: 40px;
        position: absolute !important;
        top: 1.5rem;
        left: 1rem;
        &.jw-svg-icon-buffer {
          position: inherit !important;
        }
      }
    }
    &.jw-reset {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .jw-svg-icon-play path {
    display: none;
  }
  .jw-svg-icon-play {
    background-image: url("/img/jwplayer/play.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 50%;
    z-index: 0;
  }
  .jw-nextup-container {
    display: none !important;
  }
  &.vertical {
    width: 70%;
    margin: 17px auto;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 1rem !important;
    min-height: 800px;
    height: auto;

    ${breakpoints.smallDesktop} {
      width: 70%;
    }
    ${breakpoints.phone} {
      width: 90%;
    }

    .jwplayer {
      width: 100% !important;
      top: unset;
      left: unset;
      position: relative;
      margin: 0 auto;
      height: auto !important;
    }
    .jw-display-controls {
      ${breakpoints.phone} {
        margin-left: -20px;
      }
    }
    .jw-button-container {
      margin-top: -57px;
    }
  }
`;

export const JWText = styled.div.attrs((props) => ({
  className: props.className,
}))`
  margin: auto;
  ${Font.sansRegular};
  font-size: 18px;
  color: ${Color.black000};
  line-height: 23px;
  padding-bottom: 1rem;
  letter-spacing: -0.2px;
  padding: 1rem 0;
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      color: ${ColorDark.textLight};
    }
  }
  h2 {
    ${Font.sansBold};
    font-size: 16px;
    color: ${Color.black000};
    line-height: 28px;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
  }
  .jwt-text-title {
    ${Font.sansBold};
    font-size: 16px;
    color: ${Color.black000};
    line-height: 28px;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
  }
`;

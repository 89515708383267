import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";


export const ShareAuthor = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;

 .socialLink {
    width: 25px;
    height: 25px;
    background: #f1f1f1;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    &:before {
            height: 0px;
      width: 0px;
      margin-right: 0px;
     
    }
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background: ${ColorDark.darkGrey};
      }
    }

    a {
      &.twitter {
        display: flex;
      }
      svg {
        width: 13px;
        height: 13px;
        ${breakpoints.darkMode} {
          [data-theme='dark'] & {
          fill: ${ColorDark.textLight};
          }
        }
      }
      }
    }
  }
`;
import ContentImage from "@/shared/ContentImage";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import { getDataImageFromRelated, getImageByClippings } from "helpers";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import Separator from "@/shared/Separator";
import dynamic from "next/dynamic";

const StyledContent = {
  afterBody: dynamic(() => import("./afterBody.style")),
  body: dynamic(() => import("./body.style")),
};

export const RelatedNews = ({ value, titleNewsEmbed, title, typeStyle = "afterBody", asPath, contentList = "mira tambien" }) => {
  const TemplateStyled = StyledContent[typeStyle];
  const images = getDataImageFromRelated(value.related);
  const titleSeparator = titleNewsEmbed ?? "Mirá también";

  return (
    <TemplateStyled>
      <div className="separator-title">
        <Separator sectionClass={'sepFullAdaptive'}  title={titleSeparator} />
      </div>
      <div className="inner-card-m">
        <div className="related-content">
          <LinkAbsolute
            href={value.url}
            aria-label={value.title}
            onClick={() => clickContent(
              { contentType: value.title, contentList: contentList },
              value.url,
              asPath,
              "mira_tambien_dinamico"
              )}
          />
          <div className="text">
              <span className="body-title">{titleSeparator}</span>
          <h3>{title ?? value.title}</h3>
              </div>
          <ContentImage
            data={getImageByClippings(
              { desktop: ["col_listado_1x1"], mobile: ["col_listado_1x1"] },
              images.clippings
            )}
            alt={value.title}
          />
        </div>
      </div>
    </TemplateStyled>
  );
};

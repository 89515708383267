import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const FeaturedNews = styled.section.attrs((props) => ({
  className: props.className,
}))`
  ${Font.sansRegular};
  color: ${Color.black000};
  hr {
    margin:0;
    border: 0 none;
    &:before {
      content: "";
      height: 1px;
      width: 100%;
      background:#E0E0E0;
      display: block;
      margin: 3px 0;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        background: ${ColorDark.greySeparator};  
        }
      }  
    }
    &:after {
      content: "";
      height: 1px;
      width: 100%;
      background:#E0E0E0;
      display: block;
      margin-top: 5px;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        background: ${ColorDark.greySeparator};  
        }
      }  
    }
  }
.title{
  font-size: 16px;
  ${Font.sansBold};
    color: ${Color.black000};
    margin-top: 3px;
    display: block;
    padding: 1rem 0;
    line-height: 28px;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      color: ${ColorDark.textLight};  
      }
    }  
    ${breakpoints.phone} {
      font-size: 17px;

    }
}
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    column-gap: 0.5rem;

    li {
      border: 1px solid #e0e0e0;
      width: calc((100% - 1rem) / 3);
      border-radius: 5px;
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      display: flex;
      flex-direction:column;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        border: 1px solid ${ColorDark.textLight};  
        }
      } 

      ${breakpoints.phone} {
        width: 100%;
        flex-direction: column;
      }
      .comments{
        position: relative;
        display: flex;
        padding: 0.5rem;
        align-items: center;
        &::before {
        content: "";
        background: url("/img/comments.svg") no-repeat;
        height: 13px;
        width: 12px;
        position: absolute;
        background-size: contain;
        left: 0.5rem;

      }
      span{
        align-items: unset;
        margin-left: 1rem;
        font-size: 11px;
        ${Font.sansRegular};
        line-height: 25px;
letter-spacing: 0.11px;
      }
      }
      p {
        padding: 0.5rem;
        ${Font.sansSemiBold};
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.17px;
        ${Font.sansRegular};
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        align-self: start;
        ${breakpoints.darkMode} {
          [data-theme='dark'] & {
         color: ${ColorDark.textLight};  
          }
        } 
        ${breakpoints.phone} {
          font-size: 17px;
          padding: 0.5rem;
          line-height: 21px;

        }
      }
      img {
        object-fit: contain;
        border-radius: 5px 5px 0 0;
      }
    }
  }
`;

export const FeaturedNewsContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: relative;
  margin: 0 auto;
  ${breakpoints.desktop} {
    max-width: 100%;
  }
`;
